.page {
    max-width: 1000px;
    width: 98%;
    height: 100%;
    margin: 0 auto;
    padding-top: 40px;
}
.pageContent {
    margin-top: 20px;
    padding-bottom: 20px;
}
.pageHeader {
    margin: 0;
}

.form-field {
    margin-bottom: 20px;
}
.form-field label {
    font-family: Roboto,sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
}
.form-field input[type="text"] {
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 5px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    font-style: normal;
}
.form-field textarea {
    display: block;
    width: 100%;
    height: 120px;
    background: none;
    border: 1px solid #ddd;
    padding: 12px;
    font-size: 14px;
    font-style: normal;
}
.form-field select {
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 5px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    font-style: normal;
}
.form-submit {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    font-size: 14px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
    width: 100%;
}
.form-submit:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
.auth-form-submit:hover {
    opacity: 0.8;
}
.table, .table th, .table td {
    border: 1px solid #333;
    border-collapse: collapse;
}
.table {
    width: 100%;
}
.none-screen {
    text-align: center;
}
.info-modal, .confirm-modal, .delete-modal {
    position: absolute;
    background: white;
    border: 1px solid #333333;
    outline: none;
}
.info-modal {
    top: calc(50% - 60px);
    left: calc(50% - 200px);
    right: calc(50% - 200px);
    bottom: calc(50% - 60px);
    padding: 20px 30px 20px 50px;
}
.confirm-modal {
    top: calc(50% - 60px);
    left: calc(50% - 190px);
    right: calc(50% - 190px);
    bottom: calc(50% - 60px);
    text-align: center;
    padding: 20px 0;
}
.confirm-modal-big {
    top: calc(50% - 120px);
    left: calc(50% - 220px);
    right: calc(50% - 220px);
    bottom: calc(50% - 120px);
    padding: 20px;
}
.delete-modal {
    top: calc(50% - 80px);
    left: calc(50% - 210px);
    right: calc(50% - 210px);
    bottom: calc(50% - 80px);
    text-align: center;
    padding: 20px 10px;
}
.info-modal-close, .confirm-modal-close, .modal-close {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    outline: none;
}
.info-main-text, .delete-main-text {
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 1em;
}
.info-sub-text {
    font-size: 0.9em;
}
.confirm-main-text {
    margin-bottom: 20px;
}
.delete-sub-text {
    font-size: 0.9em;
    margin-bottom: 1em;
}

.approveDeclineSet {

}
.approveDeclineSet button {
    border: none;
    color: white;
    padding: 10px 20px;
    margin-right: 15px;
    cursor: pointer;
    box-shadow: 2px 2px 1px 0 rgba(0,0,0,0.3);
    font-family: inherit;
    border-radius: 20px;
    outline: none;
}
.approveDeclineSet button:hover {
    opacity: 0.7;
}
.approve {
    background: #00b200;
}
.decline {
    background: #f64e4c;
}
button.neutral {
    background: #eeeeee;
    color: black;
}

.button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
}

.deleteButton {
    text-transform: uppercase;
    margin-top: 40px;
    background: #B71C1C;
    border-radius: 10px;
    padding: 9px 15px;
}

/* For mega warranty and user tables */
.admin-table-container, .admin-filter-area, .admin-summary-area {
    max-width: 100%;
}
.table.admin-table {
    border: none;
}
.table.admin-table td, .table.admin-table th{
    border: none;
    border-bottom: 1px solid #333333;
    padding: 8px 5px;
}
.admin-table th {
    text-align: left;
    font-size: 12px;
    color: #777777;
    cursor: pointer;
    position: relative;
    padding-right: 5px;
}
.admin-table-sort {
    position: absolute;
    right: 1px;
}

.admin-filter-area {
    font-size: 0.9em;
    text-align: right;

}
.admin-filter-input-container {
    margin-bottom: 10px;
}
.admin-filter-input-label {
    font-size: 0.85em;
}
input.admin-filter-input {
    display: inline-block;
    width: 200px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 6px 8px;
    border-radius: 3px;
    font-style: normal;
}
select.admin-filter-input {
    display: inline-block;
    width: 200px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 6px;
    border-radius: 3px;
    font-style: normal;
}

.admin-summary-area {
    margin-top: 15px;
    margin-bottom: 10px;
}
.warranties-summary-overall-selector-area {
    width: 35%;
    box-sizing: border-box;
    display: inline-block;
    border-right: 1px solid #aaaaaa;
    vertical-align: middle;
    padding: 10px 0;
}
.warranties-summary-individual-selector-area {
    width: 65%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 0;
}

.warranties-summary-overall-selector-box {
    width: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
}
.warranties-summary-box-active {
    background: #f2f2f2;
}
.warranties-summary-overall-icon {
    font-size: 2.5em;
    display: inline-block;
    margin: 12px 10% 12px 20%;
    vertical-align: middle;
}
.warranties-summary-overall-container {
    display: inline-block;
    vertical-align: middle;
}
.warranties-summary-overall-title {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}
.warranties-summary-overall-value {
    display: block;
    font-size: 0.9em;
}

.warranties-summary-individual-selector-box {
    width: 25%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 20px;
    position: relative;
}
.warranties-summary-individual-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.warranties-summary-individual-title {
    display: inline-block;
    width: calc(100% - 26px);
    vertical-align: middle;
    font-size: 0.8em;
    font-weight: 500;
}
.warranties-summary-individual-value {
    display: block;
    font-size: 0.9em;
    margin-top: 8px;
    margin-left: 25px;
}

.warranties-add-new {
    color: white;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    background-color: #a94442;
    padding: 5px 10px;
    border: none;
    outline: none;
    float: right;
}
.my-devices-add-new:hover {
    color: #aaaaaa;
}
.warranties-add-new-icon {
    font-size: 14px;
    display: inline-block;
    margin-right: 5px;
    padding-top: 4px;
}
.warranties-add-new-text {
    font-size: 13px;
    display: inline-block;
}
.users-summary-box {
    width: 33.33%;
    display: inline-block;
    /*border: 1px solid #333333;*/
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 15px;
}
.users-summary-box-active {
    background: #f2f2f2;
}
.users-summary-icon {
    font-size: 2.5em;
    display: inline-block;
    margin: 12px 10% 12px 20%;
    vertical-align: middle;
}
.users-summary-container {
    display: inline-block;
    vertical-align: middle;
}
.users-summary-title {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}
.users-summary-value {
    display: block;
    font-size: 0.9em;
}
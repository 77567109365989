.homeDashboardTimeSelectorSection {
    text-align: right;
    margin: 5px 0;
    font-size: 0.7em;
}
.homeDashboardTimeSelector {
    display: inline-block;
    padding: 6px 10px;
    border: 1px solid #dddddd;
    border-right: 0;
    cursor: pointer;
    width: 50px;
    text-align: center;
}
.homeDashboardTimeSelector:hover,.homeDashboardTimeSelector.active {
    background: #eeeeee;
}
.homeDashboardTimeSelector:first-of-type {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
}
.homeDashboardTimeSelector:last-of-type {
    border-right: 1px solid #dddddd;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}

.homeDashboardSummaryBox {
    width: 22%;
    margin-right: 4%;
    display: inline-block;
    padding: 10px 20px;
    position: relative;
    border-radius: 3px;
    text-decoration: none;
}
.homeDashboardSummaryIcon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.homeDashboardSummaryTitle {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.8em;
    font-weight: 500;
}
.homeDashboardSummaryNumber {
    font-size: 1.3em;
    margin-top: 14px;
    margin-bottom: 2px;
    margin-left: 25px;
}
.homeDashboardSummaryChange {
    position: absolute;
    bottom: 10px;
    right: 25px;
    font-size: 0.9em;
}
.homeDashboardSummaryChangeIcon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    padding-top: 3px;
}
.homeDashboardSummaryChangeNumber {
    display: inline-block;
    vertical-align: middle;
}

.homeDashboardLogSingleAboveContainer {
    border-bottom: 1px solid #aaaaaa;
}
.homeDashboardLogSingleAboveContainer:last-of-type {
    border-bottom: 0;
}
.homeDashboardLogSingleContainer {
    display: inline-block;
    padding: 12px 8px;
}
.homeDashboardLogSingleIcon {
    color: white;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding: 6.5px 7px 3.5px 7px;
}
.homeDashboardLogSingleIcon svg{
    font-size: 1.2em;
}
.homeDashboardLogSingleTextContainer {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}
.homeDashboardLogSingleText {
    color: #222222;
    font-size: 0.9em;
}
.homeDashboardLogSingleDate {
    margin-top: 3px;
    color: #7d7d7d;
    font-size: 0.7em;
}

.add-new-warranty-checkbox-container {
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    margin: 0 0 20px;
    padding: 0 0 0 30px;
    width: 50%;
}
.add-new-warranty-checkbox-container-full-width {
    width: 100%;
}
.add-new-warranty-checkbox-container input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}
.add-new-warranty-checkbox-text::before {
    width: 20px;
    height: 20px;
    line-height: 18px;
    background: #ddd;
    border: 1px solid #a9a9a9;
    display: block;
    content: "";
    font-size: 13px;
    color: #333;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
}
.add-new-warranty-checkbox-container input[type=checkbox]:checked + .add-new-warranty-checkbox-text::before {
    content: "\2714";
}
.add-new-warranty-special-input-header {
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 0.6em;
}
.fullPageContainer {
    height: 100vh;
    width: 100%;
    position: relative;
    /*padding-bottom: 35px;*/
    overflow: auto;
}
.fullPageChildrenHolder {
    width: 100%;
    height: calc(100% - 50px);
}
.fullPageContainer .pageFooterContainer {
    position: absolute;
    bottom: 8px;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    color: #333;
    font-size: 12px;
}
.fullPageContainer .pageFooterContainer .pageFooterLink {
    color: #52b4f1;
}
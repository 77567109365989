.progressbar-wrapper {
    background: #fff;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 5px;
}
.progressbar {
    margin: 0;
    padding: 0;
}
.progressbar li {
    list-style-type: none;
    /*width: 20%;*/
    float: left;
    font-size: 14px;
    position: relative;
    text-align: center;
    color: #7d7d7d;
}
.progressbar li:before {
    width: 20px;
    height: 20px;
    content: "";
    line-height: 20px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 3px auto;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    background-color: #fff;
}
.progressbar li:not(:first-child):after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 8px;
    left: -50%;
    z-index: 0;
}
.progressbar li:first-child:after {
    content: none;
}
.progressbar li.active {
    color: #ef3354;
    font-weight: bold;
}
.progressbar li.active:before {
    border-color: #bf2843;
    background: #ef3354;
}
.progressbar li.previous {
    color: #2F0A10;
}
.progressbar li.previous:before {
    border-color: #2F0A10;
    background: #2F0A10;
}
.progressbar li.previous + li:after {
    background-color: #2F0A10;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Clearfix */
.clearfix::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/* Everything box-sizing */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.auth-container {
    height: 100%;
}
.auth-container-input-side {
    width: 600px;
    height: 100%;
    float: right;
    position: relative;
    overflow: auto;
}
.auth-container-img-side {
    width: calc(100% - 600px);
    height: 100%;
    float: left;
}
.auth-container-img-side .centredLogoBox {
    width: 200px;
    height: 200px;
    background-color: white;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    border-radius: 30px;
}

.auth-container-img-side .centredLogoBox .centredLogoIcon {
    width: 150px;
    display: block;
    position: relative;
    top: 49%;
    transform: translateY(-50%);
    margin: 0 auto;
}
.auth-input-side-stuff-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
}
@media only screen and (max-height: 460px) {
    .auth-input-side-stuff-holder {
        position: inherit;
        top: unset;
        left: unset;
        transform: none;
        margin: 0.5em auto;
    }
}
@media only screen and (max-width: 1050px) {
    .auth-container-input-side {
        width: 100%;
        float: none;
        z-index: 3;
    }
    .auth-container-img-side {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        float: none;
        z-index: 2;
    }
    .auth-container-img-side .centredLogoBox {
        display: none;
        visibility: hidden;
    }
    .auth-input-side-stuff-holder {
        background: white;
        padding: 10px 40px 20px;
        width: 380px;
        max-width: 100%;
    }
}
@media only screen and (max-width: 320px) {
    .auth-input-side-stuff-holder {
        padding: 10px 20px 20px;
    }
}

.auth-form-field {
    margin-bottom: 20px;
}
.auth-form-field label {
    display: block;
    font-family: Roboto,sans-serif;
    margin: 0 0 5px;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
}
.auth-form-field input {
    display: block;
    width: 100%;
    height: 50px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    font-style: normal;
}
.auth-form-submit {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    font-size: 14px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
    width: 100%;
}
.auth-form-submit:hover {
    opacity: 0.8;
}

/* Checkbox styling */
/* Customize the label (the container) */
.auth-checkbox-field {
    display: block;
    float: left;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 11px;
    color: #888888;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.auth-checkbox-field input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.auth-checkbox-field .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: 1.5px solid #aaaaaa;
}

/* On mouse-over, add a grey background color */
.auth-checkbox-field:hover input[type=checkbox]~.checkmark {
    background-color: #eaeaea;
}

/* Create the checkmark/indicator (hidden when not checked) */
.auth-checkbox-field .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.auth-checkbox-field input[type=checkbox]:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.auth-checkbox-field .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 6px;
    border: solid #888888;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
}

#loginForgotPassword {
    float: right;
    font-size: 11px;
    color: #888;
    font-weight: 500;
    cursor: pointer;
}
.auth-options-section {
    margin: 20px 0;
}

.auth-page-header {
    text-align: center;
    font-size: 2em;
    margin: 0.5em 0;
}
.auth-page-info {
    font-size: 0.9em;
    margin-bottom: 2em;
    text-align: center;
}

.incorrectAuthenticationText {
    color: #a94442;
    margin-bottom: 1em;
    font-size: 1em;
}
.auth-error-modal {
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 200px);
    right: calc(50% - 200px);
    bottom: calc(50% - 60px);
    background: #feeff0;
    border: 1px solid #e81b37;
    padding: 20px 30px 20px 50px;
    outline: none;
}
.auth-modal-close {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    outline: none;
}
.auth-error-modal .auth-modal-close {
    color: #e81b37;
}
.auth-error-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 18px;
    color: #e81b37;
}
.auth-error-main-text {
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 1em;
}
.auth-error-sub-text {
    font-size: 0.9em;
}
.auth-extra-link-section {
    margin-top: 1.2em;
    font-size: 0.8em;
}

.fullScreenLoadingPage {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 3s;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */

.fullScreenLoadingPage img {
    display: block;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
}
.fitInBoxLoadingPage {
    text-align: center;
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 3s;
}
.fitInBoxLoadingPage img {
    width: 50%;
    max-width: 250px;
}

.NotFound {
    padding-top: 100px;
    text-align: center;
}
.fullPageContainer {
    height: 100vh;
    width: 100%;
    position: relative;
    /*padding-bottom: 35px;*/
    overflow: auto;
}
.fullPageChildrenHolder {
    width: 100%;
    height: calc(100% - 50px);
}
.fullPageContainer .pageFooterContainer {
    position: absolute;
    bottom: 8px;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    color: #333;
    font-size: 12px;
}
.fullPageContainer .pageFooterContainer .pageFooterLink {
    color: #52b4f1;
}
.NavbarAboveContainer {
    height: 50px;
    width: 100%;
    outline: none;
}
.NavbarContainer {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
}
.NavbarLogo {
    height: 29px;
    display: inline-block;
    margin-top: 14px;
    margin-right: 30px;
}
.NavbarDropdown {
    position: absolute;
    right: 0px;
    top: 20px;
    font-size: 12px;
    line-height: 20px;
    color: #52B4F1;
    cursor: pointer;
}
.NavbarDropdownIcon {
    margin-top: -2px;
    margin-left: 3px;
    color: #333;
}
.NavbarUserIcon {
    position: absolute;
    height: 40px;
    right: 0px;
    top: 10px;
    border-radius: 50%;
}
.NavbarMenuContainer {
    position: absolute;
    right: 0;
    top: 45px;
    width: 100px;
    background-color: #ffffff;
    color: #222222;
    border-radius: 3px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 3;
}
/* Note that NavbarMenuItem can be either a link or a div  */
.NavbarMenuItem {
    border-bottom: 1px solid #dedede;
    color: #777777;
    font-size: 12px;
    font-weight: 500;
    padding: 7px 10px 5px 10px;
    cursor: pointer;
    width: 100%;
    display: block;
}
.NavbarMenuItem:hover {
    background: #dddddd;
    text-decoration: none;
    color: #777777;
}
.NavbarLinkArea {
    display: inline-block;
    vertical-align: 10px;
}
.NavbarLink {
    margin: 0 0 0 60px;
    display: inline-block;
    position: relative;
    color: #999;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
}
.NavbarLinkActive, .NavbarLink:hover {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

@media only screen and (max-width: 1000px)  {
    .NavbarContainer {
        width: 94%;
    }
}

@media only screen and (max-width: 850px) {
    .NavbarContainer {
        text-align: center;
    }
    .NavbarLink {
        margin: 10px;
        display: block;
        color: white;
        font-size: 16px;
    }
    .NavbarLinkActive, .NavbarLink:hover {
        color: white;
        font-weight: bold;
    }
    .NavbarLogo {
        margin-right: 0;
    }

    /* Burger menu only exists below 850px */
    /* Position and sizing of burger button */
    .bm-burger-button {
        position: absolute;
        width: 27px;
        height: 25px;
        left: 3%;
        top: 18px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #364260;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #bdc3c7;
    }

    /* General sidebar styles */
    .bm-menu {
        background: #364260;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
        padding: 0.8em;
    }

    /* Individual item */
    .bm-item {
        display: inline-block;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
}

.warranties-summary-overall-selector-area {
    width: 35%;
    box-sizing: border-box;
    display: inline-block;
    border-right: 1px solid #aaaaaa;
    vertical-align: middle;
    padding: 10px 0;
}
.warranties-summary-individual-selector-area {
    width: 65%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 0;
}

.warranties-summary-overall-selector-box {
    width: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
}
.warranties-summary-box-active {
    background: #f2f2f2;
}
.warranties-summary-overall-icon {
    font-size: 2.5em;
    display: inline-block;
    margin: 12px 10% 12px 20%;
    vertical-align: middle;
}
.warranties-summary-overall-container {
    display: inline-block;
    vertical-align: middle;
}
.warranties-summary-overall-title {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}
.warranties-summary-overall-value {
    display: block;
    font-size: 0.9em;
}

.warranties-summary-individual-selector-box {
    width: 25%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 20px;
    position: relative;
}
.warranties-summary-individual-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.warranties-summary-individual-title {
    display: inline-block;
    width: calc(100% - 26px);
    vertical-align: middle;
    font-size: 0.8em;
    font-weight: 500;
}
.warranties-summary-individual-value {
    display: block;
    font-size: 0.9em;
    margin-top: 8px;
    margin-left: 25px;
}

.warranties-add-new {
    color: white;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    background-color: #a94442;
    padding: 5px 10px;
    border: none;
    outline: none;
    float: right;
}
.my-devices-add-new:hover {
    color: #aaaaaa;
}
.warranties-add-new-icon {
    font-size: 14px;
    display: inline-block;
    margin-right: 5px;
    padding-top: 4px;
}
.warranties-add-new-text {
    font-size: 13px;
    display: inline-block;
}
.page {
    max-width: 1000px;
    width: 98%;
    height: 100%;
    margin: 0 auto;
    padding-top: 40px;
}
.pageContent {
    margin-top: 20px;
    padding-bottom: 20px;
}
.pageHeader {
    margin: 0;
}

.form-field {
    margin-bottom: 20px;
}
.form-field label {
    font-family: Roboto,sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
}
.form-field input[type="text"] {
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 5px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    font-style: normal;
}
.form-field textarea {
    display: block;
    width: 100%;
    height: 120px;
    background: none;
    border: 1px solid #ddd;
    padding: 12px;
    font-size: 14px;
    font-style: normal;
}
.form-field select {
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 5px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    font-style: normal;
}
.form-submit {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    font-size: 14px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
    width: 100%;
}
.form-submit:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
.auth-form-submit:hover {
    opacity: 0.8;
}
.table, .table th, .table td {
    border: 1px solid #333;
    border-collapse: collapse;
}
.table {
    width: 100%;
}
.none-screen {
    text-align: center;
}
.info-modal, .confirm-modal, .delete-modal {
    position: absolute;
    background: white;
    border: 1px solid #333333;
    outline: none;
}
.info-modal {
    top: calc(50% - 60px);
    left: calc(50% - 200px);
    right: calc(50% - 200px);
    bottom: calc(50% - 60px);
    padding: 20px 30px 20px 50px;
}
.confirm-modal {
    top: calc(50% - 60px);
    left: calc(50% - 190px);
    right: calc(50% - 190px);
    bottom: calc(50% - 60px);
    text-align: center;
    padding: 20px 0;
}
.confirm-modal-big {
    top: calc(50% - 120px);
    left: calc(50% - 220px);
    right: calc(50% - 220px);
    bottom: calc(50% - 120px);
    padding: 20px;
}
.delete-modal {
    top: calc(50% - 80px);
    left: calc(50% - 210px);
    right: calc(50% - 210px);
    bottom: calc(50% - 80px);
    text-align: center;
    padding: 20px 10px;
}
.info-modal-close, .confirm-modal-close, .modal-close {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    outline: none;
}
.info-main-text, .delete-main-text {
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 1em;
}
.info-sub-text {
    font-size: 0.9em;
}
.confirm-main-text {
    margin-bottom: 20px;
}
.delete-sub-text {
    font-size: 0.9em;
    margin-bottom: 1em;
}

.approveDeclineSet {

}
.approveDeclineSet button {
    border: none;
    color: white;
    padding: 10px 20px;
    margin-right: 15px;
    cursor: pointer;
    box-shadow: 2px 2px 1px 0 rgba(0,0,0,0.3);
    font-family: inherit;
    border-radius: 20px;
    outline: none;
}
.approveDeclineSet button:hover {
    opacity: 0.7;
}
.approve {
    background: #00b200;
}
.decline {
    background: #f64e4c;
}
button.neutral {
    background: #eeeeee;
    color: black;
}

.button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
}

.deleteButton {
    text-transform: uppercase;
    margin-top: 40px;
    background: #B71C1C;
    border-radius: 10px;
    padding: 9px 15px;
}

/* For mega warranty and user tables */
.admin-table-container, .admin-filter-area, .admin-summary-area {
    max-width: 100%;
}
.table.admin-table {
    border: none;
}
.table.admin-table td, .table.admin-table th{
    border: none;
    border-bottom: 1px solid #333333;
    padding: 8px 5px;
}
.admin-table th {
    text-align: left;
    font-size: 12px;
    color: #777777;
    cursor: pointer;
    position: relative;
    padding-right: 5px;
}
.admin-table-sort {
    position: absolute;
    right: 1px;
}

.admin-filter-area {
    font-size: 0.9em;
    text-align: right;

}
.admin-filter-input-container {
    margin-bottom: 10px;
}
.admin-filter-input-label {
    font-size: 0.85em;
}
input.admin-filter-input {
    display: inline-block;
    width: 200px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 6px 8px;
    border-radius: 3px;
    font-style: normal;
}
select.admin-filter-input {
    display: inline-block;
    width: 200px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 6px;
    border-radius: 3px;
    font-style: normal;
}

.admin-summary-area {
    margin-top: 15px;
    margin-bottom: 10px;
}

.single-warranty-details {
    font-size: 0.9em;
}
.single-warranty-details-item {
    margin-top: 10px;
}
.single-warranty-details-label {
    font-weight: 500;
}
.single-warranty-header {
    font-size: 1.4em;
}

.warranty-decline-modal .form-field {
    text-align: left;
}
.warranty-decline-modal .form-field textarea {
    height: 60px;
    margin-top: 10px;
}

.single-warranty-postage-input-area {
    max-width: 400px;
    width: 100%;
    margin-bottom: 40px;
}

.warranty-no-profile-container {
    margin-bottom: 1.2em;
    font-size: 0.8em;
    background-color: #f2f2f2;
    color: #a94442;
    padding: 10px;
    display: inline-block;
}

.warranty-out-of-warranty-flag {
    margin-top: 5px;
    padding: 5px 8px;
    background: #cc0022;
    color: white;
    display: inline-block;
    font-size: 0.7em;
    border-radius: 5px;
}

.form-field.single-warranty-input-form-field label {
    font-size: 13px;
}
.form-field.single-warranty-input-form-field input {
    display: block;
    margin-top: 5px;
}
.progressbar-wrapper {
    background: #fff;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 5px;
}
.progressbar {
    margin: 0;
    padding: 0;
}
.progressbar li {
    list-style-type: none;
    /*width: 20%;*/
    float: left;
    font-size: 14px;
    position: relative;
    text-align: center;
    color: #7d7d7d;
}
.progressbar li:before {
    width: 20px;
    height: 20px;
    content: "";
    line-height: 20px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 3px auto;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    background-color: #fff;
}
.progressbar li:not(:first-child):after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 8px;
    left: -50%;
    z-index: 0;
}
.progressbar li:first-child:after {
    content: none;
}
.progressbar li.active {
    color: #ef3354;
    font-weight: bold;
}
.progressbar li.active:before {
    border-color: #bf2843;
    background: #ef3354;
}
.progressbar li.previous {
    color: #2F0A10;
}
.progressbar li.previous:before {
    border-color: #2F0A10;
    background: #2F0A10;
}
.progressbar li.previous + li:after {
    background-color: #2F0A10;
}

.homeDashboardTimeSelectorSection {
    text-align: right;
    margin: 5px 0;
    font-size: 0.7em;
}
.homeDashboardTimeSelector {
    display: inline-block;
    padding: 6px 10px;
    border: 1px solid #dddddd;
    border-right: 0;
    cursor: pointer;
    width: 50px;
    text-align: center;
}
.homeDashboardTimeSelector:hover,.homeDashboardTimeSelector.active {
    background: #eeeeee;
}
.homeDashboardTimeSelector:first-of-type {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
}
.homeDashboardTimeSelector:last-of-type {
    border-right: 1px solid #dddddd;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}

.homeDashboardSummaryBox {
    width: 22%;
    margin-right: 4%;
    display: inline-block;
    padding: 10px 20px;
    position: relative;
    border-radius: 3px;
    text-decoration: none;
}
.homeDashboardSummaryIcon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.homeDashboardSummaryTitle {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.8em;
    font-weight: 500;
}
.homeDashboardSummaryNumber {
    font-size: 1.3em;
    margin-top: 14px;
    margin-bottom: 2px;
    margin-left: 25px;
}
.homeDashboardSummaryChange {
    position: absolute;
    bottom: 10px;
    right: 25px;
    font-size: 0.9em;
}
.homeDashboardSummaryChangeIcon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    padding-top: 3px;
}
.homeDashboardSummaryChangeNumber {
    display: inline-block;
    vertical-align: middle;
}

.homeDashboardLogSingleAboveContainer {
    border-bottom: 1px solid #aaaaaa;
}
.homeDashboardLogSingleAboveContainer:last-of-type {
    border-bottom: 0;
}
.homeDashboardLogSingleContainer {
    display: inline-block;
    padding: 12px 8px;
}
.homeDashboardLogSingleIcon {
    color: white;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding: 6.5px 7px 3.5px 7px;
}
.homeDashboardLogSingleIcon svg{
    font-size: 1.2em;
}
.homeDashboardLogSingleTextContainer {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}
.homeDashboardLogSingleText {
    color: #222222;
    font-size: 0.9em;
}
.homeDashboardLogSingleDate {
    margin-top: 3px;
    color: #7d7d7d;
    font-size: 0.7em;
}

.users-summary-box {
    width: 33.33%;
    display: inline-block;
    /*border: 1px solid #333333;*/
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 15px;
}
.users-summary-box-active {
    background: #f2f2f2;
}
.users-summary-icon {
    font-size: 2.5em;
    display: inline-block;
    margin: 12px 10% 12px 20%;
    vertical-align: middle;
}
.users-summary-container {
    display: inline-block;
    vertical-align: middle;
}
.users-summary-title {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}
.users-summary-value {
    display: block;
    font-size: 0.9em;
}
.single-user-details {
    font-size: 0.9em;
}
.single-user-details-item {
    margin-top: 10px;
}
.single-user-details-label {
    font-weight: 500;
}
.single-user-header {
    font-size: 1.4em;
}
.add-new-warranty-checkbox-container {
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    margin: 0 0 20px;
    padding: 0 0 0 30px;
    width: 50%;
}
.add-new-warranty-checkbox-container-full-width {
    width: 100%;
}
.add-new-warranty-checkbox-container input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}
.add-new-warranty-checkbox-text::before {
    width: 20px;
    height: 20px;
    line-height: 18px;
    background: #ddd;
    border: 1px solid #a9a9a9;
    display: block;
    content: "";
    font-size: 13px;
    color: #333;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
}
.add-new-warranty-checkbox-container input[type=checkbox]:checked + .add-new-warranty-checkbox-text::before {
    content: "\2714";
}
.add-new-warranty-special-input-header {
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 0.6em;
}
/**
*** SIMPLE GRID
*** (C) ZACH COLE 2016
**/

/* ==== GRID SYSTEM ==== */

.row {
  position: relative;
  width: 100%;
}


.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%;
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;
}

.col-1-sm {
  width: 4.33%;
}

.col-2-sm {
  width: 12.66%;
}

.col-3-sm {
  width: 21%;
}

.col-4-sm {
  width: 29.33%;
}

.col-5-sm {
  width: 37.66%;
}

.col-6-sm {
  width: 46%;
}

.col-7-sm {
  width: 54.33%;
}

.col-8-sm {
  width: 62.66%;
}

.col-9-sm {
  width: 71%;
}

.col-10-sm {
  width: 79.33%;
}

.col-11-sm {
  width: 87.66%;
}

.col-12-sm {
  width: 96%;
}

.row::after {
	content: "";
	display: table;
	clear: both;
}

.hidden-sm {
  display: none;
}

@media only screen and (min-width: 45em) {  /* 720px */
  .col-1 {
    width: 4.33%;
  }

  .col-2 {
    width: 12.66%;
  }

  .col-3 {
    width: 21%;
  }

  .col-4 {
    width: 29.33%;
  }

  .col-5 {
    width: 37.66%;
  }

  .col-6 {
    width: 46%;
  }

  .col-7 {
    width: 54.33%;
  }

  .col-8 {
    width: 62.66%;
  }

  .col-9 {
    width: 71%;
  }

  .col-10 {
    width: 79.33%;
  }

  .col-11 {
    width: 87.66%;
  }

  .col-12 {
    width: 96%;
  }

  .hidden-sm {
    display: block;
  }
}

.fileInputContainer {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}
.fileInputDropzone {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
}
.fileInputDropzone:focus {
    border-color: #2196f3;
}

.NavbarAboveContainer {
    height: 50px;
    width: 100%;
    outline: none;
}
.NavbarContainer {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
}
.NavbarLogo {
    height: 29px;
    display: inline-block;
    margin-top: 14px;
    margin-right: 30px;
}
.NavbarDropdown {
    position: absolute;
    right: 0px;
    top: 20px;
    font-size: 12px;
    line-height: 20px;
    color: #52B4F1;
    cursor: pointer;
}
.NavbarDropdownIcon {
    margin-top: -2px;
    margin-left: 3px;
    color: #333;
}
.NavbarUserIcon {
    position: absolute;
    height: 40px;
    right: 0px;
    top: 10px;
    border-radius: 50%;
}
.NavbarMenuContainer {
    position: absolute;
    right: 0;
    top: 45px;
    width: 100px;
    background-color: #ffffff;
    color: #222222;
    border-radius: 3px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 3;
}
/* Note that NavbarMenuItem can be either a link or a div  */
.NavbarMenuItem {
    border-bottom: 1px solid #dedede;
    color: #777777;
    font-size: 12px;
    font-weight: 500;
    padding: 7px 10px 5px 10px;
    cursor: pointer;
    width: 100%;
    display: block;
}
.NavbarMenuItem:hover {
    background: #dddddd;
    text-decoration: none;
    color: #777777;
}
.NavbarLinkArea {
    display: inline-block;
    vertical-align: 10px;
}
.NavbarLink {
    margin: 0 0 0 60px;
    display: inline-block;
    position: relative;
    color: #999;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
}
.NavbarLinkActive, .NavbarLink:hover {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

@media only screen and (max-width: 1000px)  {
    .NavbarContainer {
        width: 94%;
    }
}

@media only screen and (max-width: 850px) {
    .NavbarContainer {
        text-align: center;
    }
    .NavbarLink {
        margin: 10px;
        display: block;
        color: white;
        font-size: 16px;
    }
    .NavbarLinkActive, .NavbarLink:hover {
        color: white;
        font-weight: bold;
    }
    .NavbarLogo {
        margin-right: 0;
    }

    /* Burger menu only exists below 850px */
    /* Position and sizing of burger button */
    .bm-burger-button {
        position: absolute;
        width: 27px;
        height: 25px;
        left: 3%;
        top: 18px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #364260;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #bdc3c7;
    }

    /* General sidebar styles */
    .bm-menu {
        background: #364260;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
        padding: 0.8em;
    }

    /* Individual item */
    .bm-item {
        display: inline-block;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
}
